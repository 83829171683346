document.querySelector('html').classList.remove('no-js');
document.querySelector('html').classList.add('js');

import './sass/main.scss';

import './js/clients-slider/init';
// import './js/buttons/bubbly-button';

// import './js/mobile-toogle/init';

// import './js/geometryShow';
